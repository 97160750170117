import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonB from "../components/ButtonB"

import "../styles/engineering-challenge.scss"

const ThankYouUK = () => (
  <Layout>
    <SEO title="Thank you" />
    <div className="thanks-bkg">
        <div className="eng-chal-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Thank you!</h1>
                  <h2 style={{ width: "100%", textTransform:"none", fontWeight:"500" }}>
                    Your form submission has been received.
                  </h2>
                  <ButtonB href="/">Home</ButtonB>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Layout>
)

export default ThankYouUK
